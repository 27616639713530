import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";

// Notification
import Vue from "vue";

export default function useFamiliesList() {
  // Use toast



  // if (Designations) emit('addDesignation')
  const refFamiliesListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "person_name", label: " اسم الفرد", sortable: true },

    {
      key: "field_name",
      label: " تعديل على",

      sortable: true,
    },
    {
      key: "original_field_value",
      label: " قبل ",

      sortable: true,
    },
    {
      key: "updated_field_value",
      label: " بعد ",

      sortable: true,
    },
    {
      key: "created_by",
      label: " قام بالتعديل ",
      formatter: title,
      sortable: true,
    },
    // { key: 'status', sortable: true },
    { key: "action_id", label: "الخيارات" },
  ];
  const tableColumnsDiseases = [
    { key: "name", label: "المرض", sortable: true },



    // { key: 'status', sortable: true },
    { key: "action_id", label: "الخيارات" },
  ];
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100, 1000];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refFamiliesListTable.value
      ? refFamiliesListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = () => {
    refFamiliesListTable.value.refresh();
  };
  const selectedItem = ref([])

  watch([currentPage, perPage, searchQuery], () => {
    refetchData();
  });

  const onFiltered = (filteredItems) => {
    // Trigger pagination to update the number of buttons/pages due to filtering
    totalUsers.value = filteredItems.length
    currentPage.value = 1
  }
  const responseAll = (modificationslist, approvestate) => {
    //console.log(modificationslist)
    //console.log(approvestate)
    store
      .dispatch("app-family/responseAll", {
        modifications: modificationslist,
        approval_status: approvestate

      })
      .then((response) => {


        selectedItem.value = []
        Vue.swal({
          title: `${response.data.message}`,
          icon: "success",

          confirmButtonText: "موافق",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        refetchData();
      })
      .catch(() => {
        Vue.swal({
          title: 'حدثت مشكلة في استرجاع البيانات',
          icon: "error",

          confirmButtonText: "موافق",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      });
  };

  const sendResponse = (action_id, approvestate) => {
    store
      .dispatch("app-family/sendResponse", {
        action_id: action_id,
        approvestate: approvestate

      })
      .then((response) => {

        Vue.swal({
          title: `${response.data.message}`,
          icon: "success",

          confirmButtonText: "موافق",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        refetchData();
      })
      .catch(() => {
        Vue.swal({
          title: 'حدثت مشكلة في استرجاع البيانات',
          icon: "error",

          confirmButtonText: "موافق",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      });
  };
  // const products = ref([])
  const deleteItem = (id) => {
    Vue.swal({
      icon: "warning",
      title: "هل انت متأكد من الحذف",
      confirmButtonText: "موافق",
      cancelButtonText: "الغاء",
      customClass: {
        confirmButton: "btn btn-primary",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        store
        .dispatch("app-family/deleteDiseases", {
          id: id
        })
        .then((response) => {
          refetchData();
          Vue.swal({
            title: ' تم الحذف بنجاح',
            icon: "success",
  
            confirmButtonText: "موافق",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
  
  
        })
        .catch(() => {
          Vue.swal({
            title: 'حدثت مشكلة في استرجاع البيانات',
            icon: "error",
  
            confirmButtonText: "موافق",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
      }})
  
  }
  const fetchModificatio = (ctx, callback) => {
    store
      .dispatch("app-family/fetchModification", {

        page_size: perPage.value,

        page: currentPage.value,
      })
      .then((response) => {
        //console.log(response.data)
        const { data } = response.data;
        totalUsers.value = response.data.meta.total
        callback(data);


      })
      .catch(() => {
        Vue.swal({
          title: 'حدثت مشكلة في استرجاع البيانات',
          icon: "error",

          confirmButtonText: "موافق",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      });
  };
  const fetchDiseases = (ctx, callback) => {
    store
      .dispatch("app-family/fetchDiseases", {

        per_page: perPage.value,

        page: currentPage.value,
      })
      .then((response) => {

        const { data } = response.data.data;
        callback(data);


      })

  };
  return {
    fetchModificatio,
    fetchDiseases,
    onFiltered,
    deleteItem,
    tableColumns,
    tableColumnsDiseases,
    perPage,
    currentPage,
    sendResponse,
    responseAll,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refFamiliesListTable,

    refetchData,
    selectedItem
  };
}
