<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-card-code title="إضافة مرض">
          <b-row>
            <b-col md="3" xl="3">
           
                <b-form-group label-for="">
                
                   <validation-provider
                #default="{ errors }"
                name="diseaes"
                rules="required"
              >
                  <label> المرض</label>
                  <b-form-input v-model="documentName" id="input-default" />
                   <small class="text-danger">{{ errors[0] }}</small>
                   </validation-provider>
                </b-form-group>
                 
             
            </b-col>
         
            <b-col md="1" xl="3">
             
                 <b-button v-if="isLoading"  variant="purple" disabled class="mr-1">
                Loading...
             
                </b-button>
                 <b-button v-else variant="purple" @click="addDocument">
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle"> إضافة </span>
              </b-button>
            </b-col>
          </b-row>
        </b-card-code>
      </b-form>
    </validation-observer>
    <b-card-code title=" الامراض">
   <list ref="diseaseList" />
    </b-card-code>
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import list from './list.vue'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BRow,
  BCol,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";
import { required, max } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BFormDatepicker,
  BFormCheckbox,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BButton,
  BForm,
  BFormInvalidFeedback,
} from "bootstrap-vue";
export default {
  name: "",

  components: {
required,
    BFormInvalidFeedback,

    BForm,
    ValidationObserver,
    ValidationProvider,

    BCardCode,
    BButton,
    BFormInput,
 
    BFormGroup,

    BRow,
    list,

    BCol,
  },

  data() {
    return {
      documentName: "",
      documentFor: "",
      isLoading:false,
      documentType: [
        {
          value: 1,
          label: "العائلة",
        },
        {
          value: 2,
          label: "الأفراد",
        },
        {
          value: 3,
          label: "كلاهما",
        },
      ],

      pageLength: 3,
      dir: false,

      columns: [
        {
          label: "نوع الوثيقة ",
          field: "name",
        },

        {
          label: "الخيارات",
          field: "id",
          sortable: true,
        },
      ],
      rows: [],
      searchTerm: "",
      id: "",
    };
  },

  created() {
 
  },

  methods: {
    addDocument() {
        return new Promise((resolve, reject) => {
        this.$refs.simpleRules
          .validate()
          .then((success) => {
            if (success) {
                let data = {
        name: this.documentName,
     
      };
      this.isLoading=true
      this.$http.post("/api/v1/diseases", data).then((res) => {
        //console.log(res);
        this.isLoading=false
        this.documentName= ""
   this.$refs.diseaseList.refetchData();
        
        this.$swal({
          title: "تم إضافة المرض بنجاح",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      });
    
            }})})},
  
    deleteDocument(id) {
      this.$swal({
        icon: "warning",
        title: "هل انت متأكد من الحذف",
        confirmButtonText: "موافق",
        cancelButtonText: "الغاء",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "لا",
        },
      }).then((result) => {
        if (result.value) {
          let url = `/api/v1/person_document_types/${id}`;
          this.$http.delete(url).then((res) => {
            //console.log(res);
            this.getDocuments();
            this.$swal({
              title: "تم الحذف  بنجاح",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
        }
      });
    },

    //
  },
};
</script>

<style scoped>
label {
  color: rgba(112, 192, 203, 255);
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #7e7a9f;
  margin-top: 25px;
}

span {
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.align-left {
  left: 50px;
}
.but {
  top: 40px;
}
</style>
